<template>
    <el-container class="home-container">
        <!--    导航-->
        <el-header>

            <div style="width:100%">
                <div style="margin-left: 20px; font-size: 25px; color: #FFF">
                    <div style="float: right;margin-right: 3%; font-size: 20px; color: #FFF">
                        当前登录: {{ loginUser }}
                    </div>
                    <div>松下软件市场</div>
                </div>
            </div>

            <el-dialog
                    title="授权"
                    @close="closePassWordDialog"
                    :visible.sync="passWordDialog">
                <el-form :model="passWordMsg" :rules="passWordRules" ref="passWordRef">
                    <el-form-item
                            label="授权码:"
                            prop="authorization"
                            :label-width="formLabelWidth">
                        <el-input
                                class="update-input"
                                maxlength="12"
                                v-model="passWordMsg.authorization"
                                show-password
                                placeholder="授权码"
                        ></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="success" @click="selectAuthorization">确定</el-button>
                </div>
            </el-dialog>

            <el-dropdown>
                <div class="block">
                    <el-avatar :size="50" :src="img" style="cursor: pointer"></el-avatar>
                </div>
                <el-dropdown-menu slot="dropdown" trigger="click">
                     <el-dropdown-item>
                         <span type="danger"  @click="toPassword"><span class="el-icon-house"></span> &nbsp;修改密码</span>
                     </el-dropdown-item>
                     <div @click="logout">
                         <el-dropdown-item>
                            <span type="danger">
                                <span class="el-icon-switch-button"></span>
                                &nbsp;退出登录
                            </span>
                         </el-dropdown-item>
                     </div>
                </el-dropdown-menu>
            </el-dropdown>
        </el-header>

        <!--主体-->
        <el-container style="height: 500px">
            <!--菜单-->
            <el-aside :width="isOpen === true ? '64px' : '200px'">
                <div class="toggle-btn" @click="toggleMenu">|||</div>
                <el-menu
                        class="el-menu-vertical-demo"
                        :collapse="isOpen"
                        :router="true"
                        :default-active="activePath"
                        background-color="#001529"
                        :collapse-transition="false"
                        text-color="rgba(255,255,255,0.7)"
                        unique-opened>
                    <MenuTree :menuList="this.menuList"></MenuTree>
                </el-menu>
            </el-aside>
            <!--右边主体-->
            <el-main v-loading="loading">
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    import MenuTree from "../components/MenuTree"; //引进菜单模板

    export default {
        data() {
            return {
                isAdmin: false,
                passWordDialog: false,
                loading: true,
                activePath: "", //激活的路径
                isOpen: false,
                formLabelWidth: "125px",
                menuList: {},
                userInfo: {},
                img: require("../assets/131724.png"),
                static: "",
                loginUser: "",
                passWordRules: {
                    authorization: [
                        {required: true, message: "请输入授权码", trigger: "blur"},
                    ],
                },
                passWordMsg: {},
                menuType: false,
                authorization: LocalStorage.get("authorization") == true ? true : false
            };
        },
        components: {
            MenuTree,
        },
        methods: {

            /**
             *
             * 查询授权
             */
            selectAuthorization() {
                this.$http.post("/system/lyUser/selectAuthorization", this.passWordMsg)
                    .then((successData) => {
                        if (successData.data.static) {
                            this.$message({
                                type: "success",
                                message: "授权成功!",
                            });
                            LocalStorage.set("deleteOrderFlg", true);
                            this.passWordDialog = false;
                            localStorage.setItem("menuType", true);
                            window.location.reload();
                            this.getMenuList();
                            LocalStorage.set("authorization", true);
                        } else {
                            this.$message({
                                type: "error",
                                message: "授权码错误!",
                            });
                        }
                    });
            },

            /**
             *
             * 退出登入
             */
            async logout() {
                const res = await this.$confirm("此操作将退出系统, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消退出登入",
                    });
                });
                if (res === "confirm") {
                    LocalStorage.clearAll();
                    await this.$router.push("/login");
                }
            },

            /**
             * 去修改密码
             */
            toPassword() {
                this.$router.push("/system/password");
            },

            /**
             加载菜单数据
             */
            async getMenuList() {
                const {data: res} = await this.$http.get("system/user/findMenu");
                if (!res.success) {
                    return this.$message.error("获取菜单失败:" + res.msg);
                }
                this.menuList = res.data;
            },

            /**
             获取用户信息
             */
            async getUserInfo() {
                const {data: res} = await this.$http.get("system/user/info");
                if (!res.success) {
                    return this.$message.error("获取用户信息失败:" + res.msg);
                } else {
                    this.userInfo = res.data;
                    // console.log("用户信息：" + JSON.stringify(this.userInfo))
                    this.loginUser = this.userInfo.username;
                    this.$store.commit("setUserInfo", res.data);
                    this.getMenuList();
                    this.activePath = window.sessionStorage.getItem("activePath");
                    setTimeout(() => {
                        this.loading = false;
                    }, 500);
                }
            },

            /**
             * 菜单伸缩
             */
            toggleMenu() {
                this.isOpen = !this.isOpen;
            },

            /**
             *
             * 关闭密码框
             */
            closePassWordDialog() {
                this.$refs.passWordRef.clearValidate();
                this.passWordMsg = {};
            },

        },
        mounted() {
            this.getUserInfo();
        },
    };
</script>

<style>
    /* 为对应的路由跳转时设置动画效果 */

    .el-header {
        background-color: #001529;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        font-size: 19px;

        padding-left: 0px;
    }

    .el-aside {
        background-color: #001529;
    }

    .el-main {
        background-color: #eaedf1;
    }

    .home-container {
        width: 100%;
        height: 100% !important;
    }

    .toggle-btn {
        background-color: #2d8cf0 !important;
        font-size: 10px;
        line-height: 24px;
        color: #fff;
        text-align: center;
        letter-spacing: 0.2em;
        cursor: pointer;
    }

    .update-input {
        width: 300px;
    }
</style>
